@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
html {
	min-height: 100%;
}

body {
	margin: 0;
	padding: 0 0 100px;
	font-family: "Lato", sans-serif !important;
	background-color: #edeff0;
	min-height: 100vh;
	color: #001f30;
	font-size: 16px;
	position: relative;
}

.root {
	font-family: "Lato", sans-serif;
	font-size: 14px;
	height: 100%;
	font-weight: 400;
}

:root {
	--theme-blue: #279cf7;
	--theme-green: #01b26d;
}
.theme-blue-txt {
	color: var(--theme-blue);
}
.theme-blue-bg {
	background-color: var(--theme-blue);
}
.theme-green-txt {
	color: var(--theme-green);
}
.inline-block {
	display: inline-block;
}

.cursor-pointer {
	cursor: pointer;
}
/*Dropdown modification*/
.react-select-dropdown div > span {
	display: none;
}
.react-select-dropdown > div {
	border-color: #979797;
}
.react-select-dropdown > div:nth-child(1) > div:first-child,
.react-select-dropdown > span + div > div:first-child {
	padding: 8px;
}
.landing-container {
	background-color: #f3f9ff;
	overflow: hidden;
	padding: 0 0 100px;
	margin-bottom: -100px;
	min-height: 100vh;
}

/* Padding Classes Start */
.p-px {
	padding: 1px;
}
.p-2-75 {
	padding: 0.75rem;
}
.p-3-25 {
	padding: 1.25rem;
}

.p-4-75 {
	padding: 1.75rem;
}
.p-8 {
	padding: 2rem;
}
.p-4-25 {
	padding: 2.25rem;
}
.p-2-5 {
	padding: 2.5rem;
}
.p-2-275 {
	padding: 2.75rem;
}
.p-6 {
	padding: 3.5rem;
}
.p-7 {
	padding: 4rem;
}
.p-8 {
	padding: 5rem;
}
.py-px {
	padding-top: 1px;
	padding-bottom: 1px;
}
.py-2-75 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}
.py-3-25 {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}
.py-4-75 {
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
}
.py-4-20 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.py-4-25 {
	padding-top: 2.25rem;
	padding-bottom: 2.25rem;
}
.py-2-5 {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}
.py-2-275 {
	padding-top: 2.75rem;
	padding-bottom: 2.75rem;
}
.py-6 {
	padding-top: 3.5rem;
	padding-bottom: 3.5rem;
}
.py-7 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}
.py-8 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}
.px-px {
	padding-left: 1px;
	padding-right: 1px;
}
.px-2-75 {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}
.px-3-25 {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}
.px-4-75 {
	padding-left: 1.75rem;
	padding-right: 1.75rem;
}
.px-4-20 {
	padding-left: 2rem;
	padding-right: 2rem;
}
.px-4-25 {
	padding-left: 2.25rem;
	padding-right: 2.25rem;
}
.px-2-5 {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}
.px-2-275 {
	padding-left: 2.75rem;
	padding-right: 2.75rem;
}
.px-6 {
	padding-left: 3.5rem;
	padding-right: 3.5rem;
}
.px-7 {
	padding-left: 4rem;
	padding-right: 4rem;
}
.px-8 {
	padding-left: 5rem;
	padding-right: 5rem;
}
.pt-px {
	padding-top: 1px;
}
.pt-2-75 {
	padding-top: 0.75rem;
}
.pt-3-25 {
	padding-top: 1.25rem;
}
.pt-4-75 {
	padding-top: 1.75rem;
}
.pt-4-20 {
	padding-top: 2rem;
}
.pt-4-25 {
	padding-top: 2.25rem;
}
.pt-2-5 {
	padding-top: 2.5rem;
}
.pt-2-275 {
	padding-top: 2.75rem;
}
.pt-6 {
	padding-top: 3.5rem;
}
.pt-7 {
	padding-top: 4rem;
}
.pt-8 {
	padding-top: 5rem;
}
.pr-px {
	padding-right: 1px;
}
.pr-2-75 {
	padding-right: 0.75rem;
}
.pr-3-25 {
	padding-right: 1.25rem;
}
.pr-4-75 {
	padding-right: 1.75rem;
}
.pr-4-20 {
	padding-right: 2rem;
}
.pr-4-25 {
	padding-right: 2.25rem;
}
.pr-2-5 {
	padding-right: 2.5rem;
}
.pr-2-275 {
	padding-right: 2.75rem;
}
.pr-6 {
	padding-right: 3.5rem;
}
.pr-7 {
	padding-right: 4rem;
}
.pr-8 {
	padding-right: 5rem;
}
.pb-px {
	padding-bottom: 1px;
}
.pb-2-75 {
	padding-bottom: 0.75rem;
}
.pb-3-25 {
	padding-bottom: 1.25rem;
}
.pb-4-75 {
	padding-bottom: 1.75rem;
}
.pb-4-20 {
	padding-bottom: 2rem;
}
.pb-4-25 {
	padding-bottom: 2.25rem;
}
.pb-2-5 {
	padding-bottom: 2.5rem;
}
.pb-2-275 {
	padding-bottom: 2.75rem;
}
.pb-6 {
	padding-bottom: 3.5rem;
}
.pb-7 {
	padding-bottom: 4rem;
}
.pb-8 {
	padding-bottom: 5rem;
}
.pl-px {
	padding-left: 1px;
}
.pl-2-75 {
	padding-left: 0.75rem;
}
.pl-3-25 {
	padding-left: 1.25rem;
}
.pl-4-75 {
	padding-left: 1.75rem;
}
.pl-4-20 {
	padding-left: 2rem;
}
.pl-4-25 {
	padding-left: 2.25rem;
}
.pl-2-5 {
	padding-left: 2.5rem;
}
.pl-2-275 {
	padding-left: 2.75rem;
}
.pl-6 {
	padding-left: 3.5rem;
}
.pl-7 {
	padding-left: 4rem;
}
.pl-8 {
	padding-left: 5rem;
}
/* Pagging Classes End */
/* Margin Classes Start */
.m-px {
	margin: 1px;
}
.m-2-75 {
	margin: 0.75rem;
}
.m-3-25 {
	margin: 1.25rem;
}
.m-4-75 {
	margin: 1.75rem;
}
.m-4-20 {
	margin: 2rem;
}
.m-4-25 {
	margin: 2.25rem;
}
.m-2-5 {
	margin: 2.5rem;
}
.m-2-275 {
	margin: 2.75rem;
}
.m-6 {
	margin: 3.5rem;
}
.m-7 {
	margin: 4rem;
}
.m-8 {
	margin: 5rem;
}
.-m-px {
	margin: -1px;
}
.-m-1 {
	margin: -0.25rem;
}
.-m-2 {
	margin: -0.5rem;
}
.-m-3 {
	margin: -0.75rem;
}
.-m-4 {
	margin: -1rem;
}
.-m-5 {
	margin: -1.25rem;
}
.-m-6 {
	margin: -1.5rem;
}
.-m-7 {
	margin: -1.75rem;
}
.-m-4-20 {
	margin: -2rem;
}
.-m-9 {
	margin: -2.25rem;
}
.-m-10 {
	margin: -2.5rem;
}
.-m-11 {
	margin: -2.75rem;
}
.-m-12 {
	margin: -3rem;
}
.-m-14 {
	margin: -3.5rem;
}
.-m-16 {
	margin: -4rem;
}
.-m-8 {
	margin: -5rem;
}
.my-px {
	margin-top: 1px;
	margin-bottom: 1px;
}
.my-2-75 {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}
.my-3-25 {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}
.my-4-75 {
	margin-top: 1.75rem;
	margin-bottom: 1.75rem;
}
.my-4-20 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.my-4-25 {
	margin-top: 2.25rem;
	margin-bottom: 2.25rem;
}
.my-2-5 {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}
.my-2-275 {
	margin-top: 2.75rem;
	margin-bottom: 2.75rem;
}
.my-6 {
	margin-top: 3.5rem;
	margin-bottom: 3.5rem;
}
.my-7 {
	margin-top: 4rem;
	margin-bottom: 4rem;
}
.my-8 {
	margin-top: 5rem;
	margin-bottom: 5rem;
}
.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}
.mx-px {
	margin-left: 1px;
	margin-right: 1px;
}
.mx-2-75 {
	margin-left: 0.75rem;
	margin-right: 0.75rem;
}
.mx-3-25 {
	margin-left: 1.25rem;
	margin-right: 1.25rem;
}
.mx-4-75 {
	margin-left: 1.75rem;
	margin-right: 1.75rem;
}
.mx-4-20 {
	margin-left: 2rem;
	margin-right: 2rem;
}
.mx-4-25 {
	margin-left: 2.25rem;
	margin-right: 2.25rem;
}
.mx-2-5 {
	margin-left: 2.5rem;
	margin-right: 2.5rem;
}
.mx-2-275 {
	margin-left: 2.75rem;
	margin-right: 2.75rem;
}
.mx-6 {
	margin-left: 3.5rem;
	margin-right: 3.5rem;
}
.mx-7 {
	margin-left: 4rem;
	margin-right: 4rem;
}
.mx-8 {
	margin-left: 5rem;
	margin-right: 5rem;
}
.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
.mt-px {
	margin-top: 1px;
}
.mt-2-75 {
	margin-top: 0.75rem;
}
.mt-3-25 {
	margin-top: 1.25rem;
}
.mt-4-75 {
	margin-top: 1.75rem;
}
.mt-4-20 {
	margin-top: 2rem;
}
.mt-4-25 {
	margin-top: 2.25rem;
}
.mt-2-5 {
	margin-top: 2.5rem;
}
.mt-2-275 {
	margin-top: 2.75rem;
}
.mt-6 {
	margin-top: 3.5rem;
}
.mt-7 {
	margin-top: 4rem;
}
.mt-8 {
	margin-top: 5rem;
}
.mr-px {
	margin-right: 1px;
}
.mr-2-75 {
	margin-right: 0.75rem;
}
.mr-3-25 {
	margin-right: 1.25rem;
}
.mr-4-75 {
	margin-right: 1.75rem;
}
.mr-4-20 {
	margin-right: 2rem;
}
.mr-4-25 {
	margin-right: 2.25rem;
}
.mr-2-5 {
	margin-right: 2.5rem;
}
.mr-2-275 {
	margin-right: 2.75rem;
}
.mr-6 {
	margin-right: 3.5rem;
}
.mr-7 {
	margin-right: 4rem;
}
.mr-8 {
	margin-right: 5rem;
}
.mb-px {
	margin-bottom: 1px;
}
.mb-2-75 {
	margin-bottom: 0.75rem;
}
.mb-3-25 {
	margin-bottom: 1.25rem;
}
.mb-4-75 {
	margin-bottom: 1.75rem;
}
.mb-4-20 {
	margin-bottom: 2rem;
}
.mb-4-25 {
	margin-bottom: 2.25rem;
}
.mb-2-5 {
	margin-bottom: 2.5rem;
}
.mb-2-275 {
	margin-bottom: 2.75rem;
}
.mb-6 {
	margin-bottom: 3.5rem;
}
.mb-7 {
	margin-bottom: 4rem;
}
.mb-8 {
	margin-bottom: 5rem;
}
.ml-px {
	margin-left: 1px;
}
.ml-2-75 {
	margin-left: 0.75rem;
}
.ml-3-25 {
	margin-left: 1.25rem;
}
.ml-4-75 {
	margin-left: 1.75rem;
}
.ml-4-20 {
	margin-left: 2rem;
}
.ml-4-25 {
	margin-left: 2.25rem;
}
.ml-2-5 {
	margin-left: 2.5rem;
}
.ml-2-275 {
	margin-left: 2.75rem;
}
.ml-6 {
	margin-left: 3.5rem;
}
.ml-7 {
	margin-left: 4rem;
}
.ml-8 {
	margin-left: 5rem;
}
.-mt-px {
	margin-top: -1px;
}
.-mt-1 {
	margin-top: -0.25rem;
}
.-mt-2 {
	margin-top: -0.5rem;
}
.-mt-3 {
	margin-top: -0.75rem;
}
.-mt-4 {
	margin-top: -1rem;
}
.-mt-5 {
	margin-top: -1.25rem;
}
.-mt-7 {
	margin-top: -1.75rem;
}
.-mt-8 {
	margin-top: -2rem;
}
.-mt-9 {
	margin-top: -2.25rem;
}
.-mt-10 {
	margin-top: -2.5rem;
}
.-mt-11 {
	margin-top: -2.75rem;
}
.-mt-12 {
	margin-top: -3rem;
}
.-mt-14 {
	margin-top: -3.5rem;
}
.-mt-16 {
	margin-top: -4rem;
}
.-mt-20 {
	margin-top: -5rem;
}
.-mr-1 {
	margin-right: -0.25rem;
}
.-mr-2 {
	margin-right: -0.5rem;
}
.-mr-3 {
	margin-right: -0.75rem;
}
.-mr-4 {
	margin-right: -1rem;
}
.-mr-5 {
	margin-right: -1.25rem;
}
.-mr-6 {
	margin-right: -1.5rem;
}
.-mr-7 {
	margin-right: -1.75rem;
}
.-mr-8 {
	margin-right: -2rem;
}
.-mr-9 {
	margin-right: -2.25rem;
}
.-mr-10 {
	margin-right: -2.5rem;
}
.-mr-11 {
	margin-right: -2.75rem;
}
.-mr-12 {
	margin-right: -3rem;
}
.-mr-14 {
	margin-right: -3.5rem;
}
.-mr-16 {
	margin-right: -4rem;
}
.-mr-20 {
	margin-right: -5rem;
}
.-mb-px {
	margin-bottom: -1px;
}
.-mb-1 {
	margin-bottom: -0.25rem;
}
.-mb-2 {
	margin-bottom: -0.5rem;
}
.-mb-3 {
	margin-bottom: -0.75rem;
}
.-mb-4 {
	margin-bottom: -1rem;
}
.-mb-5 {
	margin-bottom: -1.25rem;
}
.-mb-6 {
	margin-bottom: -1.5rem;
}
.-mb-7 {
	margin-bottom: -1.75rem;
}
.-mb-8 {
	margin-bottom: -2rem;
}
.-mb-9 {
	margin-bottom: -2.25rem;
}
.-mb-10 {
	margin-bottom: -2.5rem;
}
.-mb-11 {
	margin-bottom: -2.75rem;
}
.-mb-12 {
	margin-bottom: -3rem;
}
.-mb-14 {
	margin-bottom: -3.5rem;
}
.-mb-16 {
	margin-bottom: -4rem;
}
.-mb-20 {
	margin-bottom: -5rem;
}
.-ml-px {
	margin-left: -1px;
}
.-ml-1 {
	margin-left: -0.25rem;
}
.-ml-2 {
	margin-left: -0.5rem;
}
.-ml-3 {
	margin-left: -0.75rem;
}
.-ml-4 {
	margin-left: -1rem;
}
.-ml-5 {
	margin-left: -1.25rem;
}
.-ml-6 {
	margin-left: -1.5rem;
}
.-ml-7 {
	margin-left: -1.75rem;
}
.-ml-8 {
	margin-left: -2rem;
}
.-ml-9 {
	margin-left: -2.25rem;
}
.-ml-10 {
	margin-left: -2.5rem;
}
.-ml-11 {
	margin-left: -2.75rem;
}
.-ml-12 {
	margin-left: -3rem;
}
.-ml-14 {
	margin-left: -3.5rem;
}
.-ml-16 {
	margin-left: -4rem;
}
.-ml-20 {
	margin-left: -5rem;
}
/* Margin Classes End */
/* Border radius Classes start */
.rounded-none {
	border-radius: 0px;
}
.rounded-sm {
	border-radius: 0.125rem;
}
.rounded {
	border-radius: 0.25rem;
}
.rounded-md {
	border-radius: 0.375rem;
}
.rounded-lg {
	border-radius: 0.5rem;
}
.rounded-xl {
	border-radius: 0.75rem;
}
.rounded-2xl {
	border-radius: 1rem;
}
.rounded-3xl {
	border-radius: 1.5rem;
}
.rounded-full {
	border-radius: 9999px;
}
.btn-pill {
	border-radius: 100px;
}
/*Border Classes*/
.navigation-border-right {
	border-right: 1px solid rgba(0, 0, 0, .2);
}
/* Border radius Classes End */
.breadcrumb {
	background: none;
	.breadcrumb-item + .breadcrumb-item {
		padding-left: 1rem;
		&:before {
			content: "|";
			padding-right: 1rem;
		}
	}
}
.breadcrumb-active {
	color: #001f30;
	pointer-events: none;
	&:hover {
		text-decoration: none;
		cursor: inherit;
		color: #001f30;
	}
}
.breadcrumb-default {
	color: #279cf7;
	&:hover {
		text-decoration: none;
	}
}
// Button CSS start
.login-button {
	border-radius: 28px;
	padding: 15px;
}
.login-button:disabled {
	opacity: 1;
}
.btn-primary {
	background-color: var(--theme-blue);
	border-color: var(--theme-blue);

	&:hover {
		background-color: #1e87d9;
		border-color: #1a7ac5;
	}
	.focus,
	&:focus {
		background-color: #1e87d9;
		border-color: #1a7ac5;
	}
}

.btn-outline-primary {
	color: var(--theme-blue);
	border-color: var(--theme-blue);
	&:hover {
		background-color: var(--theme-blue);
		border-color: var(--theme-blue);
	}
}
button.btn:disabled {
	color: #fff !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	background-color: #1a7ac5;
	border-color: #005cbf;
}
.btn.no-bg:hover,
.btn.no-bg:focus,
.btn.no-bg:active {
	background-color: transparent !important;
	box-shadow: none !important;
}

// Button CSS end

.form-control {
	padding: 12px;
	height: auto;
	border-color: #979797;
}
// Font sizes styling start
@mixin font12 {
	font: 400 12px Lato;
}

@mixin font12-b {
	font: 700 12px Lato;
}

@mixin font14 {
	font: 400 14px Lato;
}

@mixin font14-b {
	font: 700 14px Lato;
}

@mixin font16 {
	font: 400 16px Lato;
}

@mixin font16-b {
	font: 700 16px Lato;
}

@mixin font18 {
	font: 400 18px Lato;
}

@mixin font18-b {
	font: 700 18px Lato;
}

@mixin font20 {
	font: 400 20px Lato;
}

@mixin font20-b {
	font: 700 20px Lato;
}

@mixin font24 {
	font: 400 24px Lato;
}

@mixin font24-b {
	font: 700 24px Lato;
}

.font12 {
	font: 400 12px Lato;
}

.font12-b {
	font: 700 12px Lato;
}

.font14 {
	font: 400 14px Lato;
}

.font14-b {
	font: 700 14px Lato;
}

.font16 {
	font: 400 16px Lato;
}

.font16-b {
	font: 700 16px Lato;
}

.font18 {
	font: 400 18px Lato;
}

.font18-b {
	font: 700 18px Lato;
}

.font20 {
	font: 400 20px Lato;
}

.font20-b {
	font: 700 20px Lato;
}

.font24 {
	font: 400 24px Lato;
}

.font24-b {
	font: 700 24px Lato;
}
.font34 {
	font: 300 34px Lato;
}
// Font sizes styling end
// Line Height styling start
.leading-3 {
	line-height: 0.75rem;
}
.leading-4 {
	line-height: 1rem;
}
.leading-5 {
	line-height: 1.25rem;
}
.leading-6 {
	line-height: 1.5rem;
}
.leading-7 {
	line-height: 1.75rem;
}
.leading-8 {
	line-height: 2rem;
}
.leading-9 {
	line-height: 2.25rem;
}
.leading-10 {
	line-height: 2.5rem;
}
.leading-none {
	line-height: 1;
}
.leading-tight {
	line-height: 1.25;
}
.leading-snug {
	line-height: 1.375;
}
.leading-normal {
	line-height: 1.5;
}
.leading-relaxed {
	line-height: 1.625;
}
.leading-loose {
	line-height: 2;
}
// Line Height styling end
/*css for  react Table */

.scroller {
	margin: 0 auto;
	width: 200px;
	height: 100px;
	border: 1px solid;
	border-color: black;
	overflow: scroll;
}

.item {
	margin: 10px 0;
}

.checkboxdiv {
	display: inline;
}

.search {
	position: relative;
	border-radius: 1;
	background-color: "fade(theme.palette.common.white, 0.15)";
	margin-right: "theme.spacing(2)";
	margin-left: 0;
	width: "100%";
}

.search .searchIcon {
	width: "theme.spacing(7)";
	height: "100%";
	position: "absolute";
	pointer-events: "none";
	display: "flex";
	align-items: "center";
	justify-content: "center";
}

.search .inputRoot {
	color: "inherit";
}

.search .inputInput {
	padding: "theme.spacing(1, 1, 1, 7)";
	transition: "theme.transitions.create(width)";
	width: "100%";
}

.search :hover {
	background-color: "fade(theme.palette.common.white, 0.25)";
}

img.logo-footer {
	max-width: 110px;
}

.content-right {
	float: right;
	padding-right: 10px;
	font: 400 14px Lato;
	color: #001f30;
}

/* Ticket redemption */
.plus-minus {
	width: 20px !important;
	margin-right: 0 !important;
}

.persion-count {
	display: inline-block;
	width: 35px;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
}

.containerbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 120px;
}

.searchInput {
	width: 350px;
	position: relative;
}

/*loader */
.loader {
	position: fixed;
	z-index: 9999;
	background: rgba(218, 218, 218, 0.5) !important;
	width: 100%;
	top: 0;
	bottom: 0;
	height: 100%;
	left: 0;
	right: 0;
}

.loading-message {
	position: relative;
	margin: 100px auto;
	text-align: center;
	color: #425164;
}

.spinner {
	margin: 10px auto;
	width: 40px;
	height: 40px;
	position: absolute !important;
	text-align: center;
	left: 50%;
	bottom: 50%;
	-webkit-animation: inherit !important;
	animation: inherit !important;
}

.table .tbody .tr .tableSelected {
	background-color: red;
}

/*CSS for  Notification start */
.clearfix,
.clearfix:after {
	height: 1px;
	clear: both;
	width: 100%;
	display: block;
	content: "";
}

.bold {
	font-weight: 700;
}

.no-border {
	border: 0;
}
.no-bg {
	background: none;
}
button.btn.no-bg:disabled {
	background-color: transparent !important;
}

.dashboard_header {
	color: #fff;
	background-color: #0060a9;
	color: #fff;
	margin: -15px -15px 20px;
	padding: 20px;

	h2 {
		font: 400 36px Lato;
	}

	button {
		color: #0060a9;
		font: 700 20px Lato;

		img {
			margin-right: 5px;
		}
	}
}

.filter_header {
	background-color: #e9f5fe;
	padding: 15px;
	margin-right: 0;
	margin-left: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.filter_header h3 {
	margin-bottom: 0;
	font-size: 24px;
	font-weight: 600;
	color: #001f30;
	font-family: "Lato", sans-serif;
}

.filter_header span {
	font-size: 16px;
	cursor: pointer;
	color: var(--theme-blue);
	font-weight: 600;
	font-family: "Lato", sans-serif;
}

.text-sort {
	display: inline-block;
	float: left;
	padding: 0px 5px 0 0;
}

.sort-container {
	display: inline-block;
	height: 17px;
	width: 10px;
	overflow: hidden;
}

.sort-container img {
	display: inline-block;
	margin-top: 3px;
	float: left;
}

.filter_body {
	border: 0;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;

	.form-check-input {
		margin-left: -1rem;
	}

	.font16-b {
		display: block;
		margin-bottom: 5px;
	}

	.form-check-label {
		display: block;
		margin-bottom: 20px;
		margin-top: 10px;
		overflow: hidden;
		padding-bottom: 10px;
		@include font16;
		line-height: 28px;
	}

	.custom-select,
	.form-control {
		@include font16;
	}
}

.search_field {
	margin-right: 20px;
	float: right;

	.input-group {
		display: block;
	}

	.input-group-prepend {
		float: right;
		padding-top: 5px;
		margin-right: 0;
		transform: rotateY(170deg);
	}

	.input-group-text {
		background-color: transparent;
		border: 0;
		border-radius: 0;
		border-bottom: 2px solid #001f30;
		color: #001f30;
		font-size: 1.5rem;
		padding: 0 0.5rem 0 0;
	}

	.form-control {
		float: left;
		width: 160px;
	}
}

.display_record {
	float: right;
	padding-right: 10px;
	@include font14;

	select {
		margin: 0 30px 0 15px !important;
	}
}

.productFareListHead {
	color: #001f30;
	padding-bottom: 10px;

	.form-control {
		background-color: transparent;
		border-radius: 0;
		border: 0;
		border-bottom: 2px solid #001f30;
		padding-left: 0 !important;
		padding: 5px 5px 5px 0 !important;
		height: auto;

		&:focus {
			box-shadow: none;
		}
	}
}

.footerPagination {
	text-align: center;
	margin-top: 25px;

	.form-control {
		display: inline-block;
		text-align: center;
		width: 75px;
		font: 400 20px Lato !important;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	.form-control[type="number"] {
		-moz-appearance: textfield;
	}
	.form-control:disabled,
	.form-control[readonly] {
		background-color: white;
		padding: 10px 5px;
		border: 0px;
		border-radius: 8px;
	}

	button {
		font: 400 20px Lato !important;
		margin-top: -8px;
		height: 44px;
		border-radius: 8px;
	}
}

.mt-0 .footerPagination {
	margin-top: 0px;
}
/*CSS for  Notification end */

/*Card Box Shadow*/
.card-box-shadow-4 {
	box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	padding: 10px;
}
.card-box-shadow-8 {
	background: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
	border-radius: 8px;
}

/* Login page CSS start */
.auth-form .card.login_card {
	box-shadow: 0 2px 5px 5px rgba(159, 159, 159, 0.25);
	border-radius: 20px;
}

@media (min-width: 480px) {
	.auth-form .card.login_card {
		max-width: 496px !important;
	}
}

.login_card .form-control.error-field {
	border-width: 1px 1px 1px 1px;
	border-color: #bf1111;
}

.login_card a {
	color: #0077cc;
	font-size: 16px;
}

.login_card .form-group {
	position: relative;
}

.login_card input[id="password"] + img.password {
	position: absolute;
	right: 15px;
	top: 45px;
	color: #bfc1bf;
}

.login_card .help_msg .error-msg {
	margin-bottom: -2rem !important;
}

/* Login page CSS end */

/* Create Notification page CSS start */

.createNotification .letter_count {
	position: absolute;
	right: 15px;
	bottom: -2px;
	font-size: 13px;
	font-style: italic;
}

.notification_sub_checkbox {
	max-height: 320px;
	overflow-y: auto;
	margin-right: -142px;
}

.date_picker_width {
	position: relative;
}

.date_picker_width img {
	position: absolute;
	top: 8px;
	right: 12px;
}

.date_picker_width .react-datepicker-wrapper {
	display: block;
}

.date_picker_width input[type="text"] {
	padding-right: 40px;
}

/* Create Notification page CSS start */

.productFareListHead .btn-group {
	margin-bottom: 10px;
}

.productFareListHead .btn-group .dropdown-menu {
	max-height: 390px;
	overflow-y: scroll;
}

.productFareListHead .btn-group .dropdown-toggle::after {
	margin-right: 0.255em;
	float: right;
	margin-top: 8px;
}

.productFareListHead .custom-select {
	font-size: 0.875rem;
	height: auto;
	padding: 0.25rem 1.75rem 0.25rem 0.75rem;
	margin-bottom: 10px;
}

.productFareList {
	font-size: 14px;
	color: #001f30;

	thead th {
		vertical-align: middle;
		border-bottom: 1px solid #979797;
		@include font14-b;
	}

	td {
		@include font14;
	}

	.change-back-color {
		background-color: #8fbc8f;
	}

	.form-group {
		margin-bottom: 0;
		font-size: 11px;

		label {
			margin-bottom: 0;
		}
	}

	.react-datepicker-wrapper {
		width: 65px;

		input {
			width: 100%;
		}
	}
}

.add-img {
	display: inline-block;
	background-color: #545b62;
	color: white;
	padding: 0.5rem;
	border-radius: 0.3rem;
	cursor: pointer;
	font-size: 14px;
}

.card-tr {
	border-bottom: 1px solid #d0dbe3;
}

.hidden {
	display: none;
}

/* routes list */
/* routes list */
.routeCheckbox {
	.form-check-label {
		@include font16-b;
		line-height: 18px !important;
		padding-top: 5px;
		margin-bottom: 5px;

		input {
			float: left;
		}
	}

	.routeCheckboxList {
		.bg-light {
			position: relative;
			padding-right: 155px;
			background-color: #f7f7f7 !important;
			float: left;
			margin: 0 15px;
			width: 47%;

			.float-right {
				@include font14;
				position: absolute;
				right: 15px;
				top: 12px;
			}
		}

		.bg-light:nth-child(2n + 1) {
			float: right;
		}
	}

	li {
		padding-right: 0px !important;
		background-color: #f7f7f7 !important;

		.form-check-label {
			@include font14;
			line-height: 18px !important;
			margin-bottom: 0;
		}
	}
}

//
/* Notifications fields errors */
.error-msg {
	margin-bottom: 10px;
	margin-top: 5px;
	position: relative;
	overflow: hidden;
	min-height: 30px;
	display: flex;
	align-items: center;
	background: #ffe6e6;
	padding: 5px 12px;
	font-size: 14px;
	font-family: Helvetica;
	color: #bf1111;
	line-height: 20px;
}

.error-msg:before {
	display: none;
	position: absolute;
	content: "!";
	width: 20px !important;
	height: 20px !important;
	background: #ff4500;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	font-size: 14px !important;
	font-family: Helvetica;
	font-weight: bold;
	left: 0;
	line-height: 20px !important;
}

.error-field {
	border-color: #f0270c !important;
}

.error-field .css-yk16xz-control {
	border-color: #f0270c;
}

.error-msg-text {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: bold;
	font-family: Helvetica;
	color: #bf1111 !important;
}

//error page
.error-wrapper {
	background-color: #87c2ed;
	padding: 70px 0;
	background-size: cover;
	min-height: 800px;
	margin-top: -40px;
}

.error-wrapper h2 {
	font: 300 60px/60px Lato;
	letter-spacing: 0;
	color: #ffffff;
	margin-bottom: 25px;
}

.error-wrapper h3 {
	font: 700 44px/50px Lato;
	letter-spacing: 0;
	color: #ffffff;
	margin-bottom: 25px;
}

.error-wrapper h4 {
	font: 400 18px Lato;
	letter-spacing: 0;
	color: #ffffff;
	margin-bottom: 25px;
}

/* Landing page */
.App-logo {
	width: 292px;
	height: 154px;
	pointer-events: none;
	margin: 30px 0 30px 0;
}

.App-header {
	background-repeat: no-repeat;
	background-size: 100% 100%;
	min-height: 100vh;
	align-items: center;
	font-size: calc(10px + 2vmin);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.App-Center {
	display: flex;
	flex-flow: column;
	align-items: center;
}

.main-text {
	font-size: 42px;
	letter-spacing: 0;
	color: #0461a9;
	line-height: 44px;
	margin-bottom: 4rem;
}

.launch-select {
	width: 470px;
	margin: 0px auto;
	height: 221px;
	background: #ffffff;
	border-radius: 15px;
}

.select-text {
	font-size: 24px;
	padding: 30px 0 8px 46px;
	@include font24-b;
}

.select-dropdown {
	font-size: 16px;
	font: Bold 16px/16px Lato;
	padding: 0px 46px 8px 46px;
	height: 60px;
	width: 100% !important;
}

.footer-powered-text {
	font-size: 12px;
	color: #ffffff;
	letter-spacing: 0;
	line-height: 16px;
}

.footer-version-text {
	opacity: 0.9;
	font-size: 12px;
	color: #ffffff;
	letter-spacing: 0;
	line-height: 22px;
}

/* end */

/*Delete Modal*/
.journal-modal {
	top: 10%;
	width: 340px;
	height: 485px;
	// left: 40%;
	background: #ffffff;
	border: 1px solid #979797;
	border-radius: 8px;
	padding-right: 0 !important;
}

.journal-modal .modal-content,
.modal-header {
	border: 0;
	padding: 0;
}

.journal-modal .modal-header .close {
	padding: 0 1.5rem;
	margin: -1.5rem -1rem -1rem auto;
}

.journal-modal .modal-body {
	padding: 20px;
}

.journal-modal .modal-body img {
	margin: auto;
	justify-content: center;
	display: flex;
	margin-bottom: 15px;
}

.journal-modal .modal-body p {
	font-family: Helvetica;
	font-size: 24px;
	color: #001f30;
	letter-spacing: 0;
	text-align: center;
	line-height: 36px;
	margin: 0;
}

.journal-modal .modal-body button {
	border: 1px solid var(--theme-blue);
	border-radius: 25px;
	height: 40px;
	width: 100%;
	margin-right: 26px;
	background: var(--theme-blue);
	color: #fff;
	display: block;
	text-transform: uppercase;
}

.journal-modal .modal-body .logout-cancel {
	font-size: 16px;
	color: #0077cc;
	bottom: -30px;
	position: relative;
	cursor: pointer;
	text-transform: uppercase;
}

.journal-row-highlighter {
	color: #a91a00;

	.text-highlighter {
		color: #a91a00;
	}

	span.expanded {
		border-bottom: 5px solid #a91a00;
	}
}

.text-highlighter {
	color: #0060a9;
}

.row-depth-1 {
	background: #e5eff6;
}

.row-depth-1 .td:first-child {
	background: #fff;
}

.journal-area .btn-area button {
	border: 1px solid #0661a9;
	border-radius: 10px;
	height: 44px;
	width: 152px;
	margin-right: 26px;
	margin-top: 0px;
	color: #fff;
	font-size: 16px;
	margin-left: 25px;
	background-color: #0661a9 !important;
}

button.btn:disabled {
	background-color: #a8a8a8 !important;
	border-color: #a8a8a8;
}

.journal-modal.hight-auto {
	height: auto;
}

.out-from-scrollbar {
	padding-right: 38px !important;
}

.page-breadcrumb {
	.breadcrumb {
		padding: 0;
		margin-bottom: 0;
		background-color: transparent;
	}

	a {
		color: #fff;
		@include font16;

		&:hover {
			opacity: 0.7;
			text-decoration: none;
		}
	}

	.breadcrumb-item {
		color: #c9c9c9;
	}

	.breadcrumb-item + .breadcrumb-item::before {
		display: none;
	}
}

//Header menus

.header {
	background-color: #fff;
	box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);

	.navbar-brand {
		padding-top: 0;
	}
}

.navbar-light .main_nav.navbar-nav {
	.nav-link {
		padding: 1.3rem 0.5rem 0.75rem;
		margin: 0 0.75rem 0.75rem;
		border-bottom: 5px solid #fff;
		color: #001f30;
		@include font16;
	}

	.nav-link.active {
		border-color: var(--theme-blue);
		color: var(--theme-blue);
	}
}

.user_nav {
	span {
		background-color: #0060a9;
		display: inline-block;
		width: 35px;
		height: 35px;
		border-radius: 50%;
		text-align: center;
		line-height: 35px;
		color: #fff !important;
		@include font12;
		overflow: hidden;
		text-transform: uppercase;
		padding: 10px 0;
		position: relative;
		float: left;
		margin-right: 10px;
		text-align: center;

		label {
			width: 8px;
			overflow: hidden;
		}
	}

	img {
		float: left;
		margin-top: 12px;
	}

	#basic-nav-dropdown {
		position: relative;

		.notification_sub_checkbox {
			position: absolute;
			right: 0;
			top: 45px;
			margin: 0;
			min-width: 10rem;
			padding: 0.5rem 0;
			margin: 0.125rem 0 0;
			font-size: 1rem;
			color: #212529;
			text-align: left;
			list-style: none;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 0.25rem;
			z-index: 9999;

			li {
				display: block;
				clear: both;
				text-align: inherit;
				white-space: nowrap;
				background-color: transparent;
				border: 0;

				button {
					border: 0;
					background: none;
					width: 100%;
					padding: 0.25rem 1.5rem;
					font-weight: 400;
					color: #212529;
					text-align: left;

					&:focus,
					&:hover {
						color: #16181b;
						text-decoration: none;
						background-color: #f8f9fa;
						outline: none;
					}
				}
			}
		}
	}
}

//Notification Create modal
.confirmation-modal {
	top: 25%;
	width: 330px;
	height: auto;
	left: 40%;
	background: #ffffff;
	border: 1px solid #979797;
	border-radius: 8px;
	padding-right: 0 !important;

	.modal-content,
	.modal-header {
		border: 0;
		padding: 0;
	}

	.modal-dialog {
		border: 0;
		padding: 0;
	}

	.modal-header .close {
		padding: 0 1.5rem;
		margin: -1.5rem -1rem -1rem auto;
	}

	.modal-body {
		padding: 20px;

		img {
			margin: auto;
			justify-content: center;
			display: flex;
			margin-bottom: 15px;
		}

		h3 {
			font-size: 24px;
			color: #001f30;
			letter-spacing: 0;
			text-align: center;
			font-weight: bold;
		}

		.message {
			font-size: 18px;
			color: #001f30;
			letter-spacing: 0;
			text-align: center;
		}

		.message-error {
			font-size: 14px;
			color: #001f30;
			letter-spacing: 0;
			text-align: center;
		}

		button {
			border: 1px solid var(--theme-blue);
			border-radius: 25px;
			height: 50px;
			width: 100%;
			margin-right: 26px;
			background: var(--theme-blue);
			color: #fff;
			display: block;
			text-transform: uppercase;
			margin-top: 24px;
		}
	}
}

.datePicker_ico {
	position: relative;

	img {
		position: absolute;
		top: 15px;
		right: 18px;
	}

	.react-datepicker-wrapper,
	.react-datepicker__input-container {
		display: block;
	}

	.form-control {
		padding-right: 40px !important;
	}
}

.custom-select.form-control-lg,
.form-control.form-control-lg {
	height: auto;
	padding: 12px;
	font-size: 1rem;
}

// Custom checkbox styling start
.form-check-label,
.customCheckbox {
	line-height: 28px;
}

.form-check-label,
.customCheckbox,
.customRadio {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input[type="radio"],
	input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.radioMark,
	.checkBoxMark {
		position: absolute;
		top: 0;
		left: 0;
		height: 29px;
		width: 29px;
		border-radius: 3px;
		border: 1px solid #0062cc;
		box-shadow: 0 0 3px rgba(0, 98, 204, 0.5) inset;
	}

	&:hover input[type="radio"] ~ .radioMark,
	&:hover input[type="checkbox"] ~ .checkBoxMark {
		background-color: #f7f7f7;
	}

	input[type="checkbox"]:checked ~ .checkBoxMark {
		background-color: #0062cc;
	}

	.radioMark:after,
	.checkBoxMark:after {
		content: "";
		position: absolute;
		display: none;
	}

	input[type="checkbox"]:checked ~ .checkBoxMark:after {
		display: block;
	}

	.checkBoxMark:after {
		left: 10px;
		top: 5px;
		width: 6px;
		height: 14px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.radioMark {
		border: 2px solid #0062cc;
		border-radius: 50%;
		height: 24px;
		width: 24px;
		box-shadow: none;
	}

	input[type="radio"]:checked ~ .radioMark {
		background-color: #fff;
	}

	input[type="radio"]:checked ~ .radioMark:after {
		display: block;
	}

	.radioMark:after {
		top: 2px;
		left: 2px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #0062cc;
	}

	input:disabled ~ .checkBoxMark {
		background-color: #cbcbcb !important;
		border-color: #cbcbcb !important;
	}

	input:disabled ~ .radioMark {
		border: 2px solid #cbcbcb !important;
	}

	input:disabled ~ .radioMark:after {
		background: #cbcbcb !important;
	}
}

/* pagination-header */
.showing-result {
	margin-top: 7px;
}

.pageShow {
	width: 55px;
	display: inline-block;
}

/* create alert */
.textField {
	border: 1px solid #979797;
	border-radius: 4px;
	height: 62px;
	@include font16;
}

.startDateField {
	border: 1px solid #979797;
	border-radius: 4px;

	@include font16;
}

.footer {
	background: #f2f3f4;
	box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.14);
	padding: 4px 0;
	margin-top: 40px;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	.poweredBy {
		@include font12;

		div {
			float: left;
			display: inline-block;
			margin-top: 20px;
			margin-right: 5px;
			color: #001f30;
		}
	}

	.footerCopyright {
		@include font14;
		text-align: right;
		padding-top: 12px;
		color: #001f30;
	}
}

.no-results-modal {
	top: 25%;
	width: 400px;
	height: auto;
	left: 38%;
	right: 38%;
	background: #ffffff;
	border: 1px solid #979797;
	border-radius: 8px;
	padding-right: 0 !important;

	.modal-content,
	.modal-header {
		border: 0;
		padding: 0;
	}

	.content {
		padding-right: 20px;
		padding-left: 20px;
	}

	.modal-header .close {
		padding: 0 1.5rem;
		margin: -1.5rem -1rem -1rem auto;
	}

	.button-search {
		border: 1px solid var(--theme-blue);
		border-radius: 25px;
		height: 50px;
		width: 80%;

		background: var(--theme-blue);
		color: #fff;
		text-transform: uppercase;
		margin-top: 14px;
	}
}
//CS css
.add-notes-modal .ticket_label {
	background: #f2f2f2;
}
.ticketInfoList {
	.form-check-input {
		position: static;
		margin-left: 0;
	}
	tr:nth-child(even) {
		background: #f2f2f2;
	}
	tr td:nth-child(6),
	tr td:nth-child(7),
	tr td:nth-child(8) {
		text-align: center;
	}
	td {
		@include font16;
	}
}

.noteHistoryList {
	font-size: 14px;
	color: #001f30;

	thead th {
		vertical-align: middle;
		border-bottom: 1px solid #979797;
		@include font14-b;
	}
	tr:nth-child(even) {
		background: #f2f2f2;
	}
	td {
		@include font16;
	}

	.change-back-color {
		background-color: #8fbc8f;
	}

	.form-group {
		margin-bottom: 0;
		font-size: 11px;

		label {
			margin-bottom: 0;
		}
	}

	.react-datepicker-wrapper {
		width: 65px;

		input {
			width: 100%;
		}
	}
}
.disabledCursor {
	pointer-events: none;
	opacity: 0.7;
}

//customer support grid
.customerSupportList {
	font-size: 14px;
	color: #001f30;
	tr:nth-child(even) {
		background: #f2f2f2;
	}
	tr:nth-child(odd) {
		background: #fff;
	}
	thead th {
		vertical-align: middle;
		border-bottom: 1px solid #979797;
		@include font14-b;
	}

	td {
		@include font14;
	}

	.change-back-color {
		background-color: #8fbc8f;
	}

	.form-group {
		margin-bottom: 0;
		font-size: 11px;

		label {
			margin-bottom: 0;
		}
	}

	.react-datepicker-wrapper {
		width: 65px;

		input {
			width: 100%;
		}
	}
}
