@media all and (min-width: 480px) {
  .auth-form {
    padding: 60px 0;
  }

  .auth-form .card {
    margin: 0 auto;
    max-width: 320px;
  }
}
